import type { ObjectDirective } from 'vue'
import autoAnimate from '@formkit/auto-animate'

// v-autoanimate: Animates any element transitions after it's mounted.
const vAutoanimate: ObjectDirective<any, boolean | undefined> = {
  mounted (el, binding) {
    // We delay twice with nextTick() to avoid undesired animations due to component nesting
    return nextTick(() => {
      nextTick(() => autoAnimate(el, {}))
    })
  },
}

export default vAutoanimate
