<!--
Provides a consistent API to simplify defining inputs, making it easy
to use different input types.

- Can be used manually with v-model, or can receive data from a parent <GForm>
- Performs validations and displays errors, bubbling them up to a parent <GForm>
- Can display server errors provided by a parent <GForm>
 -->
<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';
const explicitSize = __MACROS_toRef(__props, "size");

import { toRef as __MACROS_toRef } from "vue";
import type { Component } from 'vue'
import { useFormInput } from '@consumer/services/form'
import { titleize } from '@shared/string'
import { compact, wrapArray, union } from '@shared/array'
import { debounce } from '@shared/functions'
import { presence } from '@shared/object'
import { trackEvent } from '@shared/tracking'
import {
  bindValidation,
  emailValidation,
  lengthValidator,
  maxValidator,
  minValidator,
  maxlengthValidator,
  minlengthValidator,
  requiredValidation,
  type InputValidation,
} from '@shared/validation'
import TextInput from '@consumer/components/GInputField.vue'
import GCheckbox from '@consumer/components/GCheckbox.vue'
import GDropdown from '@consumer/components/GDropdown.vue'
import GDatepicker from '@consumer/components/GDatepicker.vue'
import vAutoanimate from '@corp/directives/autoanimate'

export interface FormInputProps {
  // The name of the field, which will be used to retrieve a value from a parent form.
  field?: string

  // The input type of the field.
  type?: string

  // A value bound using v-model.
  modelValue?: any

  // The size of the input.
  size?: 'small' | 'large'

  // Additional classes for the input, applied in the wrapper.
  class?: any

  // Whether the field is disabled.
  disabled?: boolean

  // Whether the field is required.
  required?: boolean

  // The label of the field.
  label?: string | false

  // Hint to display as tooltip next to the label.
  hint?: string | object

  // Custom validation function(s).
  validate?: InputValidation & {} | (InputValidation & {})[]

  // The maximum the value must be
  max?: number

  // The minimum the value must be
  min?: number

  // The exact length the value must be
  length?: number

  // The maximum length the value must be
  maxlength?: number

  // The minimum length the value must be
  minlength?: number
}

withDefaults(defineProps<FormInputProps>(), { type: 'text',label: undefined, })

const emit = defineEmits<{ 'update:modelValue': [value: any] }>()

// The parent form, if there is one.
const { form, formData } = useFormInput(__props.field)

const attrs = useAttrs()

const id = `form-input-${__props.field || useId()}`

const inputComponentsByType: Record<string, Component> = {
  // card: StripeInput,
  checkbox: GCheckbox,
  // color: ColorInput,
  date: GDatepicker,
  // file: FileInput,
  // radio: RadioInput,
  // search: SearchInput,
  select: GDropdown,
  // switch: SwitchInput,
  // tel: PhoneNumberInput,
}

const inferredType = _computed(() =>
  inputComponentsByType.hasOwnProperty(__props.type)
    ? __props.type
    : (attrs.hasOwnProperty('options') ? 'select' : 'text'),
)
const componentObj = _computed(() => inputComponentsByType[inferredType.value] || TextInput)

// Inputs can inherit their size from the parent form.
const size = _computed(() => __props.size || (form?.size) || 'large')

// Inputs can be managed (have a parent form), or unmanaged (v-model).
const value = _computed(() => form && __props.field ? formData.value : __props.modelValue)

// Inputs can have an internal validity state.
let validity = _ref<ValidityState | undefined>()

// Validations that should be applied to the input value.
const validations = _computed<InputValidation[]>(() => compact([
  ...wrapArray(__props.validate),
  __props.required && requiredValidation,
  __props.type === 'email' && emailValidation,
  __props.max && bindValidation(maxValidator, { max: __props.max }),
  __props.min && bindValidation(minValidator, { min: __props.min }),
  __props.length && bindValidation(lengthValidator, { length: __props.length }),
  __props.maxlength && bindValidation(maxlengthValidator, { length: __props.maxlength }),
  __props.minlength && bindValidation(minlengthValidator, { length: __props.minlength }),
]) as InputValidation[])

// Errors that were gathered from the input validations.
const validationErrors = _computed(() => {
  const errors: string[] = []
  validations.value.every((validator) => {
    if (!validator.isValueValid(value.value)) {
      errors.push(validator.errorMessage(value.value, validity.value))
      if (validator.immediate) displayErrors.value = true
      if (validator === requiredValidation) return false
    }
    return true
  })
  return errors
})

// Whether the input has no validation errors.
const isValid = _computed(() => validationErrors.value.length === 0 && internalErrors.value.length === 0)

// By default errors are displayed after the user blurs the input, which usually
// means the user is done editing. Once it becomes valid, this is reset.
let displayErrors = _ref(false)

// A list of errors provided by the internal input.
let internalErrors = _ref<string[]>([])

// The combination of validation errors and server-side errors.
const errors = _computed(() => {
  if (displayErrors.value || (form && __props.field && form.displayErrors.value))
    return presence(union(validationErrors.value, internalErrors.value, formData.serverErrors))
})

const debounceTrackEvent = debounce((field: string, newValue: any) => trackEvent('change', field, newValue), 200)

function onInput (eventOrValue: Event | any, errorMessage?: string | string[] | false) {
  const newValue = eventOrValue?.target ? eventOrValue.target.value : eventOrValue
  validity.value = eventOrValue?.target?.validity

  if (errorMessage !== undefined)
    onError(errorMessage)

  if (form && __props.field) {
    form.onInputValueChange(__props.field, newValue)
    if (__props.type !== 'password')
      debounceTrackEvent(__props.field, newValue)
  }

  emit('update:modelValue', newValue)
}

// We want to display errors "just-in-time", without being annoying.
function onBlur () {
  // Display errors on blur if there's a value but it's invalid.
  // Otherwise, let required errors be surfaced on submit.
  displayErrors.value = (value.value || validity.value?.badInput) && !isValid.value
}

// Bubble up errors from the internal input type.
function onError (errorMessage: string | string[] | false) {
  if (errorMessage === false) {
    internalErrors.value = []
  }
  else {
    internalErrors.value = wrapArray(errorMessage)
    displayErrors.value = true
  }
}

const inferLabel = _computed(() => __props.field ? titleize(__props.field) : '')

if (form && __props.field) {
  watch((isValid), () => {
    if (isValid.value)
      form.onInputValid(__props.field)
    else
      form.onInputErrors(__props.field, validationErrors.value)
  }, { immediate: true })
}

defineOptions({
  inheritAttrs: false,
})

const slots = useSlots()
</script>

<template>
  <div
    v-autoanimate
    class="form-input"
    :class="[$props.class, { invalid: Boolean(errors) }]"
    :data-field="field || $attrs['data-field']"
    :data-type="inferredType"
  >
    <div v-if="label !== false || slots.hint" class="w-full flex items-center justify-between gap-1.5 mb-2.5">
      <GInputLabel
        v-if="label !== false"
        :required="required"
        :size="size"
        :label="label ?? inferLabel"
        :hasErrors="Boolean(errors)"
        :hint="hint"
        :for="id"
      >
        <slot name="labelExtras"/>
      </GInputLabel>
      <span class="font-normal text-sm text-grey-500">
        <slot name="hint">
          <span v-html="hint"/>
        </slot>
      </span>
    </div>
    <component
      :is="componentObj"
      :id="id"
      :type="type"
      :hasErrors="Boolean(errors)"
      :disabled="disabled"
      :maxlength="maxlength || length"
      :modelValue="value"
      :required="required"
      :size="size"
      :name="field"
      v-bind="$attrs"
      @update:modelValue="onInput"
      @blur="onBlur"
    >
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData"/>
      </template>
    </component>
  </div>
</template>

<style scoped lang="scss">
// NOTE: Prevent v-auto-animate from hidding the overflow of a dropdown menu inside a modal.
.form-input[data-type="select"],
.form-input[data-type="date"] {
  position: initial !important;
}
</style>
