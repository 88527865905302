<script lang="ts" setup>
import { Datepicker } from 'vanillajs-datepicker'
import { computed, onMounted, ref } from 'vue'
import { formatDate } from '@consumer/utils/date'

defineOptions({
  inheritAttrs: false,
})

withDefaults(defineProps<{
  modelValue?: Date
  minDate?: string | Date
  maxDate?: string | Date
  format?: string
  inline?: boolean
  todayButton?: boolean
  type?: string
  maxView?: number
}>(), { format: 'MM/dd/yyyy',inline: false,todayButton: false,maxView: 2, })

const emit = defineEmits<{(e: 'update:modelValue', value: any): void }>()

const formattedValue = computed(() => formatDate(__props.modelValue!, { format: __props.format, showNow: true }))

const datepickerElement = ref()
const datepicker = ref()

onMounted(() => {
  // Show Sun, Mon, Tue, ... instead of Su, Mo, Tu, ... in weeks row
  const datePickerLocales = Datepicker.locales as any
  datePickerLocales.en.daysMin = datePickerLocales.en.daysShort

  datepicker.value = new Datepicker(datepickerElement.value, {
    autohide: true,
    maxView: __props.maxView,
    minDate: __props.minDate,
    maxDate: __props.maxDate,
    defaultViewDate: __props.modelValue,
    prevArrow: '<svg aria-hidden="true"><use href="#icon-carousel-left"></use></svg>',
    nextArrow: '<svg aria-hidden="true"><use href="#icon-carousel-right"></use></svg>',
    todayHighlight: true,
    todayBtn: __props.todayButton,
    todayBtnMode: 1,
    showOnFocus: false,
  })

  if (__props.modelValue) {
    datepicker.value.setDate(__props.modelValue)
  }

  datepickerElement.value.addEventListener('changeDate', onDateChange)
})

const onDateChange = ({
  detail: { date },
}: {
  detail: { date: Date }
}) => {
  emit('update:modelValue', date)
}
</script>

<template>
  <div
    v-if="inline"
    ref="datepickerElement"
    class="w-full rounded-v2md border border-grey-primary"
    v-bind="$attrs"
  />

  <div v-else>
    <!-- So we can display a different value in the text field like "Today" -->
    <input ref="datepickerElement" type="text" class="hidden">
    <GInputField
      :value="formattedValue"
      v-bind="$attrs"
      data-testid="datepicker-input"
      @click="datepicker?.show()"
    />
  </div>
</template>

<style lang="scss">
.datepicker {
  @apply hidden;

  &.active {
    @apply block;
  }
}

.datepicker-dropdown {
  @apply absolute top-0 left-0 z-200 mt-21 shadow-v2lg;

  &.datepicker-orient-top {
    @apply pt-0 pb-1;
  }
}

.datepicker-picker {
  @apply rounded-v2md bg-white inline-block p-4;
  @apply w-full;

  .datepicker-dropdown & {
    box-shadow: 0 10px 60px rgba(0 0 0 / 10%);
  }

  span {
    @apply block flex-1 border-0 rounded-1 cursor-default select-none text-center;

    -webkit-touch-callout: none;
  }
}

.datepicker-main {
  @apply p-0;
}

.datepicker-title {
  @apply text-lg font-semibold text-center;
}

.datepicker-controls {
  @apply flex;

  .datepicker-header & {
    .button {
      @apply inline-flex relative m-0 cursor-pointer h-9 align-top;
      @apply text-grey-700 font-semibold text-xl border-none items-center outline-none;

      &:focus,
      &:active {
        @apply outline-none bg-none;
      }

      &.view-switch {
        @apply flex-grow justify-start order-1;
      }

      &.prev-btn {
        @apply mr-4;
      }

      &.prev-btn,
      &.next-btn {
        @apply p-0 order-2;

        &.disabled {
          visibility: hidden;
        }

        svg {
          @apply fill-grey-500 stroke-grey-500 h-4 w-4;

          &:hover {
            @apply fill-primary-base stroke-primary-base;
          }
        }
      }

      &[disabled] {
        @apply cursor-not-allowed pointer-events-none;

        svg {
          @apply fill-grey-300 stroke-grey-300;
        }
      }
    }
  }

  .datepicker-footer & {
    @apply flex space-x-4 border-none;

    .button {
      @apply inline-flex relative m-0 p-2 cursor-pointer flex-auto justify-center align-middle;
      @apply antialiased rounded-v2md border-none outline-none shadow-none bg-primary-base;
      @apply font-semibold capitalize text-white;
      @apply mt-2;

      &:hover {
        @apply bg-primary-dark;
      }
    }
  }
}

.datepicker-view {
  @apply flex justify-center content-around w-full;

  .days-of-week {
    @apply my-4;
  }

  .dow {
    @apply text-xs;
    @apply font-semibold uppercase text-grey-900 opacity-30;
  }

  .calendar-weeks {
    @apply w-5 flex flex-col;

    .days-of-week {
      @apply grid-cols-none mb-0;
    }

    .weeks {
      @apply flex-grow flex flex-col content-around;

      .week {
        @apply w-full flex items-end mb-2;
        @apply text-grey-400 text-sm;
      }
    }
  }

  .days {
    @apply flex-grow;
  }
}

.days-of-week,
.datepicker-grid {
  @apply grid grid-cols-7 gap-x-2 gap-y-2;
  @apply justify-items-center;
}

.datepicker-grid {
  &.months,
  &.years {
    @apply grid-cols-4;
  }
}

.datepicker-cell {
  @apply rounded-1;
  @apply text-grey-700 font-semibold;
  @apply w-8 h-8 py-1;

  font-size: 16px;
  line-height: 24px;

  .months &,
  .years & {
    @apply rounded px-4 py-2 w-auto h-auto;
  }

  &:not(.disabled):hover {
    @apply bg-primary-pale cursor-pointer;
  }

  &.selected {
    &,
    &:hover {
      @apply bg-primary-base text-white;
    }
  }

  &.disabled {
    @apply text-grey-700 opacity-30 font-normal;
  }

  &.prev,
  &.next {
    &:not(.disabled) {
      @apply opacity-60 font-normal;
    }
  }

  &.today {
    &:not(.selected) {
      &:not(.disabled) {
        @apply text-primary-base;
      }
    }

    &.focused:not(.selected) {
      @apply border-primary-base border-solid border-1;
    }
  }
}
</style>
